<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">返佣配置</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="form_box"  label-position='right' style="padding:0 20px;" label-width="140px" size="small">
                    <el-form-item label="一级用户返积分："  style="margin-top:30px;">
						<el-input  :maxlength="50" style="width:300px;" type="number" v-model="oneBackP" placeholder="请输入一级用户返积分" clearable></el-input>
                        <span style="margin-left:10px">%</span>
					</el-form-item>
                    <el-form-item label="二级用户返积分："  style="margin-top:30px;">
						<el-input :maxlength="50" style="width:300px;" type="number" v-model="twoBackP" placeholder="请输入二级用户返积分" clearable></el-input>
                        <span style="margin-left:10px">%</span>
					</el-form-item>
                    <el-form-item size="large" style="margin-top:30px;">
                        <el-button  type="primary" @click="rebateSave()" style="width:150px;">保存</el-button>
                    </el-form-item>
                </el-form>
			</div>
		</div>
	</div>
</template>

<script>
import {
    backamountView,backamountUpdate
} from "@/api/platform/index";
export default {
    name: "backamountUpdate",
    data() {
        return {
            oneBackP:'',
            twoBackP:''
        };
    },
    mounted() {
        let that = this;
        that.getData();
    },
    created() {
    },
    methods: {

        //获取列表
        getData() {
            backamountView({}).then(res => {
                if(res.code == 200){
                    this.oneBackP = res.data.oneBackP;
                    this.twoBackP = res.data.twoBackP;
                }
            })
        },
        
        // 执行操作
        rebateSave(){
            let that = this;
            if(that.oneBackP != null && that.oneBackP.toString().length > 0){
                if(that.twoBackP != null && that.twoBackP.toString().length > 0){
                    if(Number(that.oneBackP) < 0){
                        that.$message.warning('一级用户返积分不能为负数');
                        return;
                    }else if(!/^\d+$/.test(that.oneBackP)){
                        that.$message.warning("一级用户返积分必须为正整数");
                        return;
                    }else if(Number(that.twoBackP) < 0){
                        that.$message.warning('二级用户返积分不能为负数');
                        return;
                    }else if(!/^\d+$/.test(that.twoBackP)){
                        that.$message.warning("二级用户返积分必须为正整数");
                        return;
                    }else{
                        backamountUpdate({
                            oneBackP: that.oneBackP,
                            twoBackP: that.twoBackP,
                        }).then(res=>{
                            if (res.code == 200) {
                                that.$message.success('操作成功');
                                that.oneBackP = '';
                                that.twoBackP = '';
                                that.getData();
                            } else {
                                that.$message.error(res.msg);
                            }
                        }).catch(err => {
                            that.$message.error(err.msg);
                        })
                    }
                         
                }else{
                    that.$message.warning('二级用户返积分不能为空');
                }
            }else{
                that.$message.warning('一级用户返积分不能为空');
            }
        },
    }
}
</script>

<style >
.table-content {margin-top: 20px;}
.active {color: #049EC4;}
.form_box{padding:0 50px;}
.avatar{width:150px;}
.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
.flex{display:flex;align-items:center;justify-content:space-between;}

.meunTop{display:flex;align-items:center;margin-bottom:20px;}
.meunLine{width:3px;height:20px;background-color:#FFA218;}
.meunTitle{font-size:20px;margin-left:10px;}
.el-input__inner{
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}
.search .el-form-item{
    margin-bottom: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"]{
    -moz-appearance: textfield;
}
</style>
